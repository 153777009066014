/*----------------------------------------*\
    Components: Section
\*----------------------------------------*/

@mixin arrow() {
    width: clamped(36px, 64px);
    display: inline-block;
    svg {
        width: inherit;
    }
}

.section {
    @include container();
    padding-right: var(--container-padding-large);
    grid-area: content;
    align-self: flex-start;
    color: var(--color-primary);

    &.hidden {
        display: none;
    }
}

.section.intro {
    color: var(--color-accent);
    height: 100%;

    h1 {
        margin-bottom: 0;
    }

    .container {
        display: flex;
        flex-flow: column wrap;
        height: inherit;
        justify-content: flex-end;
    }

    .form {
        box-shadow: 0px clamped(4px, 12px) 0px 0px var(--color-primary-20);

        .input {
            position: relative;

            span {
                @include arrow();
                position: absolute;
                color: var(--color-primary-20);
                right: 0;
                top: calc(50% + clamped(12px, 24px));
                transform: translateY(-50%);
            }

            > input {
                &::placeholder {
                    color: var(--color-primary-20);
                    opacity: 1;
                }
            }
        }
    }
}

.section.about {
    color: var(--color-accent);
    // padding-bottom: var(--container-padding-large);

    p {
        margin: 0.5em 0 0.65em 0;
    }

    span.icon {
        @include arrow();
        margin-right: calc(var(--base-size) * 2);
        transition: 0.3s ease margin-right;
    }

    a {
        &:hover {
            span.icon {
                margin-right: calc(var(--base-size) * 2.5);
            }
        }
    }
}
