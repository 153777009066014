/*----------------------------------------*\
    Scales
\*----------------------------------------*/

:root {
    --base-size: 16px;
    --body-size: clamp(var(--base-size), 2vw + 1.5rem, 2.667rem);
}

/*----------------------------------------*\
    Typography
\*----------------------------------------*/

html,
body {
    font-family: var(--font-family);
    font-size: var(--base-size);
    color: var(--color-primary);
    background-color: var(--color-secondary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    &:not(.wf-active) {
        --font-family: sans-serif;
    }
}

/*----------------------------------------*\
    Headings, Paragraphs
\*----------------------------------------*/

h1,
.text-h1 {
    font-size: clamped(64px, 192px);
    line-height: 1em;
    font-weight: normal;
}

h2,
.text-h2 {
    font-size: clamped(32px, 64px);
    line-height: 1em;
    font-weight: normal;
}

h3,
.text-h3 {
    font-size: 1.5rem;
    line-height: 1em;
    font-weight: normal;
    text-transform: uppercase;
}

p,
.text-body {
    font-size: 1.5rem;
}

a {
    color: inherit;
    text-decoration: none;
}
