/*----------------------------------------*\
    Layout
\*----------------------------------------*/

@mixin container() {
    padding: var(--container-padding);
}

/*----------------------------------------*\
    Grid
\*----------------------------------------*/

main {
    position: relative;
    display: grid;
    grid-template-areas:
        "header header header"
        "content content content"
        "footer footer footer";
    height: 100vh;
    z-index: 1;
}
