/*----------------------------------------*\
    Base
\*----------------------------------------*/

:root {
    // Global CSS Variables
    --container-padding: #{clamped(24px, 72px)};
    --container-padding-large: #{clamped(24px, 216px)};
    --font-family: "ApfelGrotezk", sans-serif;
    --color-primary: #000;
    --color-primary-20: #d9d9d9;
    --color-secondary: #fcf6ee;
    --color-accent: #ef7b53;
}

main {
    &.theme-dark {
        --color-primary: #ffffff;
        --color-primary-20: #ffffff;
        --color-accent: #ffffff;
    }
}
