/*----------------------------------------*\
    Components: Scroll Indicator
\*----------------------------------------*/

.scroll-indicator {
    position: fixed;
    bottom: var(--container-padding);
    right: var(--container-padding);
    color: var(--color-primary);

    @media screen and (max-width: 567px) {
        display: none;
    }
}
