/*----------------------------------------*\
    Components: Form
\*----------------------------------------*/

.form {
    input {
        @include input-reset();
        width: 100%;
    }
}
