/*----------------------------------------*\
    Components: Header
\*----------------------------------------*/

.header {
    grid-area: header;
    padding: var(--base-size);
    text-align: right;
    color: var(--color-primary);

    position: sticky;
    top: 0;

    &:after {
        content: "";
        box-shadow: 0px 1px 0px var(--color-primary);
        width: 100%;
        height: 1px;
        display: block;
        margin-top: calc(var(--base-size) / 2);
    }

    a {
        line-height: 1;
        &:hover {
            color: var(--color-primary-20);
        }
    }
}
