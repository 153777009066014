@use "sass:math";
@use "sass:map";

/*----------------------------------------*\
    Functions
\*----------------------------------------*/

@function to-rems($px) {
    $rems: math.div($px, 16px) * 1rem;
    @return $rems;
}

@function rnd($number, $places: 0) {
    $n: 1;
    @if $places > 0 {
        @for $i from 1 through $places {
            $n: $n * 10;
        }
    }
    @return math.div(math.round($number * $n), $n);
}

/*----------------------------------------*\
    Helpers
\*----------------------------------------*/

// Resets

@mixin list-reset() {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

@mixin button-reset() {
    border: 0;
    padding: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin input-reset() {
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: none;
}

// Scale

$breakpoints: (
    mobile: 360px,
    desktop: 1280px,
);

$default-min-bp: map.get($breakpoints, "mobile");
$default-max-bp: map.get($breakpoints, "desktop");

@function clamped(
    $min-px,
    $max-px,
    $min-bp: $default-min-bp,
    $max-bp: $default-max-bp
) {
    $slope: math.div($max-px - $min-px, $max-bp - $min-bp);
    $slope-vw: rnd($slope * 100, 2);
    $intercept-rems: rnd(to-rems($min-px - $slope * $min-bp), 2);
    $min-rems: rnd(to-rems($min-px), 2);
    $max-rems: rnd(to-rems($max-px), 2);
    @return clamp(
        #{$min-rems},
        #{$slope-vw}vw + #{$intercept-rems},
        #{$max-rems}
    );
}
