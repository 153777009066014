/*----------------------------------------*\
    Components: Footer
\*----------------------------------------*/

.footer {
    margin: calc(var(--container-padding) * 2) 0 var(--container-padding) 0;
    a {
        font-size: clamped(14px, 16px);
        letter-spacing: 1px;
    }
}
