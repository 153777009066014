/*----------------------------------------*\
    Fonts
\*----------------------------------------*/

@font-face {
    font-family: "ApfelGrotezk";
    src: url("../fonts/ApfelGrotezk-Regular.woff2") format("woff2"),
        url("../fonts/ApfelGrotezk-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
